<template>
  <div ref="formContainer">
    <AddHeaderTitle :Header="Header" />
    <b-col cols=11>
     <AppHeaderInfoAdminList 
    :infos="infos" 
    />
    </b-col>
    <b-col cols=11>
      <b-card>
        <!-- Media -->
        <b-media class="mb-2">
          <b-row>
            <b-col cols=2>
               <b-avatar
                :text="getAvatar(admin.firstname)"
                variant="light-primary"
                size="110"
              />
            </b-col>
            <b-col
              cols="4"
              class="mt-3"
            >
              <table class="mt-2 mt-50 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Dernière connection</span>
                  </th>
                  <td class="pb-50 text-muted" >
                     {{ getDate(admin.lastConnection) }}
                  </td>
                  <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Date de création</span>
                  </th>
                  <td class="pb-50 text-muted">
                     {{ getDateCreation(admin.created_at) }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-media>
        <!-- User Info: Input Fields -->
        <b-form>
           <b-row> 
            <!-- Field: status -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Statut"
                label-for="Statut"
              >
                <b-form-checkbox
                  id="Statut"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  v-model="admin.status"
                >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                    
                </b-form-checkbox> 
                <span> </span>
              </b-form-group>
            </b-col>

            <!-- Field: role -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Role"
                label-for="role"
                
              >
                <b-form-radio-group
                  id="role"
                  v-model="admin.role"
                  :options="options"
                  name="radio-validation"
                  class="custom-control-primary"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row> 
          <b-row> 
            <!-- Field: firstname -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Firstname"
                label-for="firstname"
              >
                <b-form-input
                  id="firstname"
                  v-model="admin.firstname"
                />
              </b-form-group>
            </b-col>

            <!-- Field: lastname -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Lastname"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="admin.lastname"
                />
              </b-form-group>
            </b-col>
          </b-row>  
          <b-row cols=12>
            <!-- Field: email -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="admin.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
            <!-- Field: nickname -->
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Nickname"
                label-for="nickname"
              >
                <b-form-input
                  id="nickname"
                  v-model="admin.nickname"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <!-- Action Buttons -->
        <b-button
          variant="success"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="update(admin.id, admin)"
        >
          Modifier
        </b-button>
      </b-card> 
    </b-col>
  </div>
</template>
<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox, BFormRadioGroup
} from 'bootstrap-vue'
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue"
import Ripple from 'vue-ripple-directive'
import AddHeaderTitle from '@/app/views/components/AppHeaderTitle.vue'
import moment from 'moment'
import  { Admin } from '@/mixins/admin.js'
//import  { admin } from '@/app/views/admin/admin.js'

export default {
  name:"AdminDetail",
  directives: {
    Ripple,
  },
   components: {
    AppHeaderInfoAdminList,
    AddHeaderTitle,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    BFormRadioGroup
    
  },
  mixins: [ Admin ],
  data() {
    return {
      Header: {
			config: "Admin",
			listLink: "/admin/list",
			list: "Admin-liste",
      detailLink: "",
      detail: "Admin-detail",
			},
			infos: {
				title: "Modification des informations d'un administateur",
				content: "texte explicatif de la page",
			},
      adminId: this.$route.params.id,
      options: [
        { text: 'Admin', value: '1' },
        { text: 'Utilisateur', value: '2' },
      ],
      admins: {},
      admin:{},
    }
  },
  async mounted() {
    await this.loadAdminsList() 
    await this.getInfos() 
  },
  computed: {
    textStatus() {
      if(this.admin.status) {
        return "Actif"
      } else {
        return "Inactif"
      }
    }   
  },
  methods: {
    async loadAdminsList() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
        const response = await this.getAllAdmin()
        if (response.status == 1) {
          this.admins = response.datas
        } else {
          this.$sweetError("AF-52")
        }
        loader.hide()
      } catch {
        loader.hide()
        this.$sweetError("AF-52")
      }
    },
    getInfos() {
      try {
        this.admin = this.admins.find(element => element.id == this.adminId);
      } catch {
        this.$sweetError("AF-51")
      }
    },
    getDate(date) {
      moment.locale('fr');
      return moment(date).startOf('day').fromNow()
    },
    getDateCreation(date) {
      moment.locale('fr');
      return moment(date).format('LL')
    },
    getAvatar(firstname) {
      const init = firstname.substr(0,2)
      return init
    },
    async update(id, data) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
        const response = await this.updateAdmin(id, data)
        if (response.status == 1) {
          this.$sweetNotif("Modification réussie");
          this.$router.push({ path: `/admin/list` })
        } else {
          this.$sweetError("AF-51")
        }
        loader.hide()
      } catch {
        loader.hide()
        this.$sweetError("AF-51")
      }
    },
  },
}
</script>
